@font-face {
    font-family: 'Visby Round CF';
    src: url('VisbyRoundCF-ExtraBold.eot');
    src: local('Visby Round CF ExtraBold'), local('VisbyRoundCF-ExtraBold'),
        url('VisbyRoundCF-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('VisbyRoundCF-ExtraBold.woff2') format('woff2'),
        url('VisbyRoundCF-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('VisbyRoundCF-Heavy.eot');
    src: local('Visby Round CF Heavy'), local('VisbyRoundCF-Heavy'),
        url('VisbyRoundCF-Heavy.eot?#iefix') format('embedded-opentype'),
        url('VisbyRoundCF-Heavy.woff2') format('woff2'),
        url('VisbyRoundCF-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}



@font-face {
    font-family: 'Visby Round CF';
    src: url('VisbyRoundCF-Medium.eot');
    src: local('Visby Round CF Medium'), local('VisbyRoundCF-Medium'),
        url('VisbyRoundCF-Medium.eot?#iefix') format('embedded-opentype'),
        url('VisbyRoundCF-Medium.woff2') format('woff2'),
        url('VisbyRoundCF-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('VisbyRoundCF-ExtraLight.eot');
    src: local('Visby Round CF ExtraLight'), local('VisbyRoundCF-ExtraLight'),
        url('VisbyRoundCF-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('VisbyRoundCF-ExtraLight.woff2') format('woff2'),
        url('VisbyRoundCF-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('VisbyRoundCF-DemiBold.eot');
    src: local('Visby Round CF DemiBold'), local('VisbyRoundCF-DemiBold'),
        url('VisbyRoundCF-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('VisbyRoundCF-DemiBold.woff2') format('woff2'),
        url('VisbyRoundCF-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Visby Round CF';
    src: url('VisbyRoundCF-Bold.eot');
    src: local('Visby Round CF Bold'), local('VisbyRoundCF-Bold'),
        url('VisbyRoundCF-Bold.eot?#iefix') format('embedded-opentype'),
        url('VisbyRoundCF-Bold.woff2') format('woff2'),
        url('VisbyRoundCF-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}



@font-face {
    font-family: 'Visby Round CF';
    src: url('VisbyRoundCF-Regular.eot');
    src: local('Visby Round CF Regular'), local('VisbyRoundCF-Regular'),
        url('VisbyRoundCF-Regular.eot?#iefix') format('embedded-opentype'),
        url('VisbyRoundCF-Regular.woff2') format('woff2'),
        url('VisbyRoundCF-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Visby Round CF';
    src: url('VisbyRoundCF-Light.eot');
    src: local('Visby Round CF Light'), local('VisbyRoundCF-Light'),
        url('VisbyRoundCF-Light.eot?#iefix') format('embedded-opentype'),
        url('VisbyRoundCF-Light.woff2') format('woff2'),
        url('VisbyRoundCF-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

