.css-12rl710-MuiPaper-root-MuiDialog-paper {
    width: 100% !important;
    border-radius: 15px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 100% !important;
    border-radius: 15px !important;
}

.css-uhb5lp {
    width: 100% !important;
    border-radius: 15px !important;
}

.css-1qmc5dd {
    width: 100% !important;
    border-radius: 15px !important;
}

@media screen and (max-width: 480px) {
    .makeStyles-dialogPadding-1 {
        padding: 0rem 0rem !important;
       
    }
  }
  @media screen and (max-width: 480px) {
    .makeStyles-dialogPadding-2 {
        padding: 0rem 0rem !important;
       
    }
  }