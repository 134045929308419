.css-1s2u09g-control{
    height: 100%;
    /* background-color: #f0f0f0 !important; */
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 2px solid #0000002b !important;
}

.css-b62m3t-container {
    /* height: 100%; */
}

.css-1pahdxg-control {
    height: 100%;
    border-color: hsl(0, 29%, 97%) !important;
}