
  a,
  a:hover {
    color: #49b1a7;
    text-decoration: none;
  }
  
  b, strong{
    color: #202020;
  }
  
  .btn-primary,
  .btn-primary:hover {
    background: #0f3f8c;
    border-color: #0f3f8c;
    color: #fff;
  }

  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  background-color: rgba(254, 224, 196, 1) !important;
  -webkit-box-shadow:0 0 0 50px #FFFAF5 inset;
}
  
  h1 {
    font-size: 40px;
    line-height: 60px;
    font-weight: bold;
  }
  h1,h2,h3,h4,h5,h6{
    color: #202020;
  }

  input:disabled {
    cursor : not-allowed;
  }

  
  .react-slider__picture img {
    min-height: 300px;
    max-height: 300px;
  
    object-fit: contain;
    object-position: center center;
  }
  
  
  span.bg-tag .price {
    text-transform: uppercase;
  }
  
  button.active {
    background: #49b1a7;
  }

  .MuiDialog-paper.fullwidth {
    width: 580px;
    border-radius: 20px;
    padding: 30px 40px;
    max-width: 580px;
  }
  .MuiDialog-paper .closeicon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    background: #dcdcdc;
    text-align: center;
    line-height: 40px;
    color: #202020;
    border-radius: 50%;
    cursor: pointer;
  }
  .MuiDialog-paper.fullwidth h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-bottom: 0;
  }
  .MuiDialog-paper.fullwidth h2 h2 {
    margin-bottom: 20px;
  }
  
  .MuiDialogTitle-root {
    padding: 0;
  }
  
  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialog-paper.fullwidth button.btn.btn-primary {
    border-radius: 6px;
    padding: 10px 20px;
  }
  input.form-control,.year-input {
    border: none;
    padding: 7px 14px 7px 2px;
    font-size: 14px;
    height: 48px;
    /* background: #f0f0f0; */
    color: #202020;
    /* border-bottom: 2px solid #540174; */
    
  }
  .year-picker {
    width: 100%;
  }
  .css-yk16xz-control {
    background: #f0f0f0 !important;
    border: none;
    font-size: 14px;
    min-height: 48px !important;
    border-width: 0px !important;
  }
  .css-g1d714-ValueContainer {
    font-size: 14px;
    min-height: 44px;
  }

  .otp_input input {
    background: #FFF7EF;
    border: 1px solid #FFE1D8;
    border-radius: 10px;
    width: 60px !important;
    height: 60px;
    margin: 0 10px;
  }
  
  .otp_input {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .MuiDialog-paper.fullwidth p {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
  .MuiDialog-paper.fullwidth button.btn.btn-primary.signup {
    padding: 10px 20pxpx;
    min-width: 252px;
    margin-bottom: 10px;
  }
  .MuiDialog-paper.fullwidth .MuiDialogTitle-root {
    padding: 0;
  }

  .MuiDialog-paper.fullwidth .MuiDialogContent-root {
    padding: 0;
  }
  .form-control:focus {
    /* border: 1px solid #ccc; */
    box-shadow: none;
    outline: none;
  }
  

  .page h2 {
    font-weight: 600;
    font-size: 32px;
    color: #202020;
    margin-bottom: 20px;
  }

  .page p {
    margin: 0 0 15px;
  }

  .react-tel-input .flag-dropdown {
    background: transparent !important;
    border: none !important;
  }
  
  span.user_prefix {
    width: 48px;
    height: 48px;
    background: #80c5bd;
    display: inline-block;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
    border-radius: 50%;
    line-height: 48px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
  .user_content ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .user_content ul li a {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    padding: 10px 44px 10px 0;
    display: block;
    line-height: 21px;
    border-bottom: 1px solid #8d8d8d;
  }
  
  .user_content ul li a i {
    position: absolute;
    right: 15px;
  }
  .terms label {
    padding-left: 5px;
  }
  
  .terms label a {
    color: #007bff;
  }

  .terms .input_box {
    display: inline-block;
  }

  .succesmodal img {
    margin-bottom: 30px;
  }
  
  .succesmodal h2.succeshead {
    margin-bottom: 20px;
  }
  
  .succesmodal .suc-ccont {
    margin-bottom: 20px;
  }
  
  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    appearance: none;
    -webkit-appearance: none;
    background-color: #d5d5d5;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  input[type="checkbox"]::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    font-size: 12px;
    color: white;
    display: none;
  }

  input[type="checkbox"]:hover {
    background-color: #a5a5a5;
  }


  input[type="checkbox"]:checked {
    background: transparent linear-gradient(90deg, #E42279 0%, #6C1D63 100%);
  } 

  input[type="checkbox"]:checked::after {
    display: block;
  }

  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #6C1D63 !important;
  }


  .form-control:focus {
    /* border-color: #49b1a7; */
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent;
  }
  
  .react-tel-input .flag-dropdown.open {
    background: transparent;
  }
  .profile_container {
    display: flex;
  }
  

  button.btn.danger:hover {
    background: #fb2020;
  }


  .profile_picture {
    text-align: center;
  }
  
  .profile_picture img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
  }
  
  .profile_picture input#pic {
    display: none;
  }
  
  .profile_picture label {
    color: #0088ff;
    cursor: pointer;
  }
  .prof_img {
    background-color: #00BFFF;
    width: 28px !important;
    height: 24px !important;
    object-fit: none !important;
    margin-left: 4.2rem;
    margin-top: -2rem;
}

  .user_icon img {
    max-width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
  .MuiDialogContent-root {
    overflow: inherit;
  }


  a.disable {
    pointer-events: none;
  }

  span.tag {
    background: #e4e4e4;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 12px;
  
    margin-bottom: 5px;
    display: inline-block;
  
  }

  textarea.form-control {
    border: 1px solid #49b1a7;
    background: transparent;
    color: #000!important;
    width: 100%;
    height: 100px;
    font-size: 14px;
    padding-top: 15px;
  }
  .file_input_box_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* border: 1px solid #49b1a7; */
    /* border-radius: 5px; */
    padding: 10px 15px 2px 15px;
    font-size: 12px;
    color: #8D8D8D;
    border-bottom: 0.2px solid #00000052;


    overflow-x: scroll;
    width: 100%;
  }
  
  .file_input_box_wrapper h3 {
    font-size: 16px;
    font-weight: 500;
    color: #202020;
  }
  
  .drop_area {
    border: 1px dashed #8D8D8D;
    padding: 20px;
    text-align: center;
    margin: 0 0 6px;
    border-radius: 5px;
    /* display: block; */
    cursor: pointer;
  }
  
  .drop_area img {
    display: block;
    margin: 0 auto 4px;
  }
  
  .drop_area span {
    font-size: 12px;
    color: #8D8D8D;
  }
  
  .file_input_box_wrapper input {
    display: none;
  }
  
  .file_input_box_wrapper p.warning span {
    color: #D51515;
  }
  
  .file_input_box_wrapper p.warning {
    color: #202020;
  }

  .file_input_box_wrapper p {
    margin: 0 0 5px;
  }
  
  .file_input_box_wrapper label.btn {
    border: 1px solid #0F3F8C;
    background: rgb(15 63 140 / 10%);
    font-size: 14px;
    padding: 6px 30px;
    margin: 10px 0 0;
    color: #0F3F8C;
  }
  .react-slider__picture{
    min-height: auto!important;
  }
  
 .MuiDialog-paper.fullwidth button.succsesokay.btn {
    padding: 6px 41px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .img_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    
  }
  .added_images .img_box button {
    background: transparent;
    border: none;
    position: absolute;
    color: #fff;
    background: #d34c16;
    right: -6px;
    top: -6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  
  .added_images {
    display: flex;
    flex-direction: row;
  }
  
  .added_images .img_box {
    width: 70px;
    height:70px;
    display: inline-block;
    margin: 0 10px 10px 0;
    position: relative;
  }

  .react-slider__ul li {
    height: 50px;
  }

  button.btn.danger {
    border-color: #fb2020;
    color: #fb2020;
    
  }

  .file_input_box_wrapper.disable label {
    cursor: not-allowed!important;
    
  }

  .table td a {
    color: #007bff;
  }
  .MuiDialog-paperWidthXs {
    max-width: 500px!important;
    padding: 20px!important;
    border-radius: 25px !important;
  overflow-y: unset !important;
  } 
  @media (max-width:767px){
    .react-slider__imgZoom {
      display: none;
  }

  
  .row.terms  label {
    width: calc(100% - 30px);
    vertical-align: top;
    font-size: 14px;
  }

  .MuiDialog-paper.fullwidth h2 h2 {
    font-size: 18px;
  }
  
  .MuiDialog-paper .closeicon {
    width: 30px;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
  }
  
  .MuiDialog-paper.fullwidth p {
    font-size: 14px;
  }

  .page h2 {
    font-size: 24px;
  }

  
  }

  button.btn.danger:hover {
    background: #fb2020;
  }
  
  .table .thead-light th {
    background: #EFF6F6;
    border: none;
    color: #171F23;
    font-size: 14px;
  }
  
  .table .thead-light {
    border-radius: 8px 8px 0px 0px;
  }
  
  .table td {
    color: rgb(23 31 35 / 60%);
    font-size: 14px;
    
  }


  .MuiDialog-paper.fullwidth.subscription {
    max-width: 580px;
    width: 90%;
  }
  
  .MuiDialog-paper.fullwidth.subscription button.btn {
    padding: 10px 20px;
    height: auto;
    width: 160px;
    margin: 30px 10px 0;
  }
  
  .MuiDialog-paper.fullwidth.subscription img {
    margin: 0 0 20px;
  }
  
  .MuiDialog-paper.fullwidth.subscription h2 {
    margin: 0 0 10px;
  }
  
  .MuiDialog-paper.fullwidth.subscription button.btn.grey {
    background: #D9D9D9;
    color: #202020;
    border-color: #D9D9D9;
  }
  
  
  .login_btn_group {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  .picker_box i {
    position: absolute;
    right: 10px;
    top: 16px;
  }
  
  .picker_box {
    position: relative;
  }
  
  .react-datepicker-popper {
    width: 100%;
  }

  .react-datepicker-wrapper{
    width: 100%;
  }

  .react-datepicker__month-container {
    width: 100%;
  }
  
  .react-datepicker {
    width: 100%;
  }

  .react-datepicker__year .react-datepicker__year-text {
    width: 46%;
  }
  
  .react-datepicker__year-wrapper {
    max-width: 100%;
  }
  .MuiDialogContent-root{
    overflow-x: hidden!important;
  }


  .input_box{
    position: relative;
    display: flex;
    align-items: center;

  }
  .borderBottom {
    border-bottom: 2px solid #0000002b;
  }
  .borderBottom:hover {
    border-bottom: 2px solid #540174;
  }
  .input_box i {
    position: absolute;
    right: 12px;
    top: 17px;
    color: #3a3131e0;
  }

  .row.terms  label {
    width: calc(100% - 30px);
    vertical-align: top;
    font-size: 14px;
  }

  .MuiDialog-paper.fullwidth.subscription button.btn {
    
    margin: 10px 10px 0;
  }
  .user_icon img {
    width: 30px;
    height: 30px;
    margin-right: 3px;
  }

    footer h3 {
      margin-bottom: 15px;
  }
    h1 {
      font-size: 27px;
      line-height: 1.3;
    }
  .MuiDialog-paper.fullwidth {
    padding: 20px;
    margin: 0;
    max-width: 90%;
  }

  .login_btn_group button.btn {
    font-size: 14px;
    padding: 6px;
  }

  .MuiDialog-paper.fullwidth h2.registe-heding {
    font-weight: normal;
    max-width: 316px;
  }

  
  
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  /* max-width: 700px !important; */
}

.eaVIwL {
  padding: 0 !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  background: #FFFAF5 !important;
  border: 0px solid #FEE0C4 !important;
  border-radius: 4px !important;
}

.css-1a5icme.Mui-checked {
  color: #6C1D63 !important;
}

.css-1jaw3da {
  width: 180px !important;
}


::-webkit-scrollbar {
  width: 0px;    
  height: 0px;
}

  
  /* Track */
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff; 
  border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
  background: transparent linear-gradient(180deg,#E42279 0%,#6C1D63 100%);
  border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  background: transparent linear-gradient(180deg,#E42279 0%,#6C1D63 100%);
  }


  






  /* ------------------- smart carrot css------------------ */

  
  .mdNoPadding {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .mdNoPaddingFlexRow {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;

    
  }

  @media (max-width:580px){
    .mdNoPaddingFlexRow {
     flex-wrap: wrap;
    }    
  }

  .mdRightPadding {
    padding-right: 0rem !important;
  }

  
  .mdLeftPadding {
    padding-left: 0rem !important;
  }


  .mdRightPaddingShow {
    padding-right: 0.5rem !important;
    width: 50%;
  }
  .mdLeftPaddingShow {
    padding-left: 0.5rem !important;
    width: 50%;
  }
  .mdLeftPaddingFlexRow {
    padding-left: 0rem !important;
    display: flex !important;
    flex-direction: row !important;
  }

  @media (max-width:580px){
    .mdRightPaddingShow {
      padding: 0 1rem !important;;
      width:100%
    }
    .mdLeftPaddingShow {
       padding: 0 1rem !important;
       
       width: 100%;
    }

    .otp_input input {
      width: 45px !important;
      height: 45px;
    }

  }

  @media (max-width:360px){
    .otp_input input {
      width: 38px !important;
      height: 38px;
      margin: 0 8px;
    }
  }

  @media (max-width:320px){
    .otp_input input {
      width: 35px !important;
      height: 35px;
      margin: 0 6px;
    }
  }


  .formStyle {
    width: 100%;
  }

  .passwordDot{
    font-size: 2rem !important;
  }

  input[type='password'] {
    font-family: Verdana;
}

.thankYouDesign {
  border-radius: 25px;
  overflow-y: unset;
}