/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');
@import "./font//stylesheet.css";

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Visby Round CF', 'Titan One', sans-serif;}

ul, p {
  margin: 0;
  padding: 0;
}


.css-1480iag-MuiInputBase-root-MuiInput-root:before{
  border-bottom: none !important;
  transition: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  margin-bottom: 0.8rem !important;
}